<script setup lang="ts">
import { type FragmentType, graphql } from '@/gql';
import { ExitIcon } from '@glow/shared/components/icons';
import { logoutAndRefreshPage } from '@glow/shared/features/auth';
import { useRoutes } from '@glow/shared/lib/routes';

const userFragment = graphql(/* GraphQL */ `
  fragment AppLayoutProfilePopover_UserFragment on User {
    id
    fullName
    avatarUrl
  }
`);

const props = defineProps<{
  user: FragmentType<typeof userFragment>;
}>();

const user = useFragmentData(userFragment, () => props.user);
const { getUrl } = useRoutes();
</script>

<template>
  <div class="d-flex flex-column gap-3">
    <AppAvatar :src="user.avatarUrl" />
    <h3 class="m-0">{{ user.fullName }}</h3>
    <div class="text-black-secondary">
      Клиент |
      <NuxtLink :to="getUrl('crmHome')">Партнёр</NuxtLink>
    </div>
    <AppSeparator />
    <AppButton
      :to="getUrl('profile')"
      color="dark"
      variant="link"
      text-align="start"
    >
      Информация
    </AppButton>
    <AppButton color="dark" variant="link" text-align="start">Записи</AppButton>
    <AppSeparator />
    <AppButton
      color="dark"
      variant="link"
      text-align="start"
      :icon-start="ExitIcon"
      @click="logoutAndRefreshPage"
    >
      Выйти
    </AppButton>
  </div>
</template>
